<template>
  <div class="content-layout">
    <div class="loaded-device-operations">
      <div class="data-filter">
        <a-select v-if="filterSelected" v-model="filterSelected" style="width: 120px">
          <a-icon slot="suffixIcon" type="smile" />
          <a-select-option v-for="filter in filterOptions" :key="filter.value" :value="filter.value">
            {{ filter.label }}
          </a-select-option>
        </a-select>
        <a-input v-model="searchKeyword" class="search-keyword" clearable @keyup.enter.native="doSearch"></a-input>
        <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
      </div>
    </div>
    <a-table
      size="middle"
      :pagination="false"
      :columns="this.$constRepository.loadedDevice.columns"
      :data-source="tableDate"
      class="loaded-device-table"
      rowKey="loadedDeviceTypeId"
    >
      <template slot="createdTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
      <template slot="lastModificationTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
      <template slot="regions" slot-scope="scope">
        <span v-if="scope">
          {{ scope.map((region) => region.name).join('、') }}
        </span>
        <span v-else>-</span>
      </template>
      <span slot="action" slot-scope="scope">
        <a-button class="green-btn" ghost @click="handleDetail(scope)">详情</a-button>
      </span>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadDeviceList',
  components: {},
  mounted() {
    this.loadedPageData();
  },
  data() {
    return {
      filterSelected: this.$constRepository.loadedDevice.FILTER_OPTION_LOADED_DEVICE_NAME.value,
      filterOptions: this.$constRepository.loadedDevice.FILTER_OPTIONS,
      searchKeyword: '',
      requestParams: {},
      tableDate: [],
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
    };
  },
  methods: {
    handleDetail(record) {
      this.$router.push({ name: 'modelLoadedDeviceDetail', params: { id: record.loadedDeviceTypeId } });
    },
    processSearchKeyword() {
      this.$set(this.requestParams, 'name', undefined);
      this.$set(this.requestParams, 'loadedDeviceTypeId', undefined);
      switch (this.filterSelected) {
        case this.$constRepository.loadedDevice.FILTER_OPTION_LOADED_DEVICE_NAME.value:
          this.$set(this.requestParams, 'name', this.searchKeyword);
          break;
        case this.$constRepository.loadedDevice.FILTER_OPTION_LOADED_DEVICE_ID.value:
          this.$set(this.requestParams, 'loadedDeviceTypeId', this.searchKeyword);
          break;
        default:
          break;
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    async loadedPageData() {
      this.processSearchKeyword();
      await this.listLoadedDevices();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listLoadedDevices();
      }
    },
    async listLoadedDevices() {
      const params = {
        loadedDeviceTypeId: this.requestParams.loadedDeviceTypeId,
        name: this.requestParams.name,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.publicApi.loadDeviceApi.getLoadedDeviceTypes(params);
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.loaded-device-operations {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      // margin: 10px 0px;
      margin-left: 3%;
    }
  }

}
.loaded-device-table {
    margin-top: 1%;
  }
.pagination {
  margin-top: 3%;
}
</style>
